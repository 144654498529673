import React, {useEffect, useState} from "react";
import Slideshow from "./slideshow";
import Styles from "./styles/pr.module.css"
import WorshipServiceImage from "./images/WorshipService-1024x1024.jpg";
import SmallGroupImage from "./images/smallgroupbiblestudy-1024x1024.jpg";
import VisitorImage from "./images/visitor-1024x1024.jpg";
import VBS2024 from "./images/vbs2024.png";
import {Link} from "react-router-dom";

const Home = () => {
    const [alertText, setAlertText] = useState('');

    useEffect(() => {
        fetch('alert_message.txt')
            .then((response) => {
                if (response.ok) {
                    return response.text();
                }
                throw new Error('Failed to load the text file');
            })
            .then((text) => {
                let parts = text.split('\n');
                if(parts[1]) {
                    //Check that the alert has not expired
                    const today = new Date();
                    const inputDate = new Date(parts[1]);
                    if (today >= inputDate) {
                        // Today's date is later than or equal to the input date
                        setAlertText('');
                    } else {
                        // Today's date is earlier than the input date
                        setAlertText(parts[0].trim());
                    }
                } else {
                    setAlertText(text.trim());
                }
            })
            .catch((error) => {
                console.error('Error fetching the text file:', error);
            });
    }, []);

    return (
        <div className={Styles.pr_home_wrapper}>
            <div className={Styles.pr_home_page_alert}>{alertText}</div>
            <h2>Welcome to Prairie Ridge Church</h2>
            <div className={Styles.pr_home_info_row}>
            </div>
            <Slideshow hide_controls={true} />
            <div className={Styles.pr_home_info_row}>
                <div className={Styles.pr_home_info_text}>Our Sunday service starts at 10:30 am and usually lasts about an hour.</div>
                <div><img className={Styles.pr_home_info_image} src={WorshipServiceImage} /></div>
            </div>
            <div className={Styles.pr_home_info_row}>
                <div className={Styles.pr_home_info_text}>
                    We have Small Group Bible Study at 9:30 am.
                </div>
                <div><img className={Styles.pr_home_info_image} src={SmallGroupImage} /></div>
            </div>
            <div className={Styles.pr_home_info_row}>
                <div className={Styles.pr_home_info_text}>
                    First time visitor, or looking for a church? Take a look at <Link to="what_to_expect">What to Expect</Link>.
                </div>
                <div><img className={Styles.pr_home_info_image} src={VisitorImage} /></div>
            </div>
        </div>
    )
}
export default Home;